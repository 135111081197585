@tailwind base;
@tailwind components;
@tailwind utilities;
/* Add this to your CSS file */
.blinking {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

@layer base {
  @font-face {
    font-family: 'jacquarda';
    src: local('jacquarda'),
         url('./Fonts/jacquarda-bastarda-9/fonts/ttf/JacquardaBastarda9-Regular.ttf') format('ttf');
    font-weight: 600;
  };
}

@font-face {
  font-family: 'jacquarda';
  src: local('jacquarda'),
       url('./Fonts/jacquarda-bastarda-9/fonts/ttf/JacquardaBastarda9-Regular.ttf') format('ttf');
  font-weight: 600;
};

@font-face {
  font-family: 'NBarch';
  src: local('NBarc'),
      url('./Fonts/nba/NBArchitektPro.woff') format('woff');
  font-weight: 600;
}


@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200;12..96,400;12..96,800&family=Inria+Serif:ital,wght@0,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200;12..96,400;12..96,500;12..96,800&family=Inria+Serif:ital,wght@0,400;1,700&family=Open+Sans:wght@400;500;800&family=Play&family=Poppins:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=Inria+Serif:ital,wght@0,400;1,700&family=MuseoModerno:ital,wght@0,100..900;1,100..900&family=Open+Sans:wght@400;500;800&family=Play&family=Poppins:wght@200&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=Inria+Serif:ital,wght@0,400;1,700&family=MuseoModerno:ital,wght@0,100..900;1,100..900&family=Nerko+One&family=Open+Sans:wght@400;500;800&family=Play&family=Poppins:wght@200&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jacquarda+Bastarda+9&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Zen+Dots&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=Goldman:wght@400;700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=MuseoModerno:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=Goldman:wght@400;700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=MuseoModerno:ital,wght@0,100..900;1,100..900&family=Pixelify+Sans:wght@400..700&family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monda:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Goldman:wght@400;700&display=swap');



body {
    @apply font-poppins;  
  }


