.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
  }
  
  .loader-icon-wrapper {
    position: relative;
    perspective: 1000px;
  }
  .loader-icon {
    animation: flip 1.50s linear infinite;
    transform-style: preserve-3d; /* Keep front and back faces visible */
  }
  
  @keyframes flip {
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(-180deg);
    }
    100% {
      transform:
  
   rotateY(0deg);
    }
  }
  
  