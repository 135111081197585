 .react-calendar {
    width: 500px;
    height: 400px;
    border-radius: 14px;
    padding: 35px;
    z-index: 999;
  }

@media (max-width: 480px) {
  .react-calendar {
    width: 100%;
    height: 100%;
    border-radius: 14px;
    padding: 35px;
    z-index: 999;
  }
}


.react-calendar__navigation{
    color: #7F5AF0;
}


::-webkit-scrollbar {
  display: none;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
 box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(141, 139, 139, 0.842);

}